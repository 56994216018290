import React from "react";

const EllipsePagination = ({ currentPage, totalPages, onNumberClick }) => {
  const getEllipsisRange = () => {
    const range = [];
    const ellipsisStart = currentPage - 2;
    const ellipsisEnd = currentPage + 2;

    for (let i = ellipsisStart; i <= ellipsisEnd; i++) {
      if (i > 0 && i <= totalPages) {
        range.push(i);
      }
    }

    return range;
  };

  return (
    <div className="sm:flex items-center justify-center space-x-2 ">
      {currentPage > 3 && (
        <button
          className="px-2 py-1 rounded hover:bg-[#E7F6FB] focus:bg-gray-200 text-xs sm:text-lg	"
          onClick={() => onNumberClick(1)}
        >
          1
        </button>
      )}

      {currentPage > 4 && (
        <span className="px-2 py-1 text-xs sm:text-lg">...</span>
      )}

      {getEllipsisRange().map((pageNumber) => (
        <button
          key={pageNumber}
          className={`px-2 py-1 rounded hover:bg-[#E7F6FB] focus:bg-gray-200 text-xs ${
            pageNumber === currentPage ? "text-[#07BBE3]" : ""
          } sm:text-lg`}
          onClick={() => onNumberClick(pageNumber)}
        >
          {pageNumber}
        </button>
      ))}

      {currentPage < totalPages - 3 && (
        <span className="px-2 py-1 text-xs sm:text-lg">...</span>
      )}

      {currentPage < totalPages - 2 && (
        <button
          className="px-2 py-1 rounded hover:bg-[#E7F6FB] focus:bg-gray-200 text-xs sm:text-lg"
          onClick={() => onNumberClick(totalPages)}
        >
          {totalPages}
        </button>
      )}
    </div>
  );
};
export default EllipsePagination;
