import React from "react";
import Logo from "../../../images/icons/logo-dark.svg";
// import Apple from "../../../images/icons/apple.svg";
// import PlayStore from "../../../images/icons/playStore.png";
import { DOWNLOAD } from "../../../assets/Links";
import { Link } from "gatsby";
// import Button from "../../UI/Button";
import DownloadButtons from "../../UI/DownloadButtons";
import { LazyLoadImage } from "react-lazy-load-image-component";

const DownloadAppBar = ({ className = "" }) => {
  return (
    <div className={`w-full  md:w-100 xl:w-[1200px] ${className}`}>
      <div className="w-full px-[18px]  pt-[10px] md:px-5 lg:px-0">
        <div className="w-full bg-gradient-to-r from-[#e5f8da] to-[#d9f5ef] rounded-2xl px-2 py-1 lg:px-4 flex flex-row justify-between items-center">
          <div className="flex sm:flex  items-center h-20 mr-2">
            <LazyLoadImage
              src={Logo}
              className="mr-0.5 h-8 sm:md:lg:xl:h-14 "
              alt="Flora Logo"
            />
            <div className=" leading-[0.7rem]   md:leading-[1.5rem] ml-2 md:ml-3">
              <div className="mb-1">
                <span className="text-[16px] lg:text-2xl font-avenir800">
                  Flora
                </span>
              </div>
              <span className="text-[11px] font-bold font-avenir400 md:text-base lg:text-xl md:font-avenir900 pr-2">
                Identify and care for your plants - for free!
              </span>
              <br />
              {/* <span className="text-[11px] font-semibold font-avenir400 md:text-base lg:text-xl md:font-avenir900 ">
                Protect your loved ones and find out if your plants are toxic
              </span> */}
            </div>
          </div>

          <div
            className="flex  justify-between  md:block md:w-2/5 lg:w-1/3"
            id="navbar-default"
          >
            <DownloadButtons
              className="hidden  md:flex h-12 lg:h-14 w-full justify-between px-5"
              appStoreClass="h-full w-full ml-5 rounded-xl"
              playStoreClass="h-full w-full rounded-xl pr-5"
            />
            <Link
              to={DOWNLOAD}
              target="_blank"
              className="w-[102px]  h-[38px]  text-white  justify-center items-center font-avenir900 px-[21px] py-[7px]   text-[16px] bg-gradient-to-br from-[#78dd4a] to-[#38c7be]  rounded-full hover:bg-gradient-to-bl  md:hidden"
            >
              Get app
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppBar;
