import React from "react";

const LeftArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-1"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 12C20 11.7348 19.8967 11.4805 19.7128 11.293C19.5289 11.1055 19.2794 11.0002 19.0193 11.0002H6.34975L10.5607 6.70896C10.7449 6.52122 10.8483 6.26659 10.8483 6.00108C10.8483 5.73558 10.7449 5.48095 10.5607 5.29321C10.3766 5.10547 10.1268 5 9.86643 5C9.60601 5 9.35626 5.10547 9.17212 5.29321L3.28811 11.2921C3.19678 11.385 3.12432 11.4953 3.07489 11.6168C3.02545 11.7383 3 11.8685 3 12C3 12.1315 3.02545 12.2617 3.07489 12.3832C3.12432 12.5047 3.19678 12.615 3.28811 12.7079L9.17212 18.7068C9.35626 18.8945 9.60601 19 9.86643 19C10.1268 19 10.3766 18.8945 10.5607 18.7068C10.7449 18.5191 10.8483 18.2644 10.8483 17.9989C10.8483 17.7334 10.7449 17.4788 10.5607 17.291L6.34975 12.9998H19.0193C19.2794 12.9998 19.5289 12.8945 19.7128 12.707C19.8967 12.5195 20 12.2652 20 12Z"
        fill="#07BBE3"
      />
    </svg>
  );
};

export default LeftArrow;
