import React from "react";
import SearchLogo from "../../images/icons/search.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

const SearchBar = ({ searchQuery, searchHandler, placeHolder }) => (
  <div className="z-0 relative w-full">
    <LazyLoadImage
      src={SearchLogo}
      alt="searchLogo"
      className="absolute w-5 ml-4 mt-0.5 py-2"
    />
    <input
      name="s"
      type="text"
      id="header-search"
      className="w-100 md:w-full pl-12 py-1 text-base border-none font-normal h-10 bg-[#F2F2F7] bg-clip-padding rounded-xl  m-0  placeholder-[#A0ACB7] "
      value={searchQuery}
      onChange={searchHandler}
      placeholder={placeHolder}
    />
  </div>
);

export default SearchBar;
